<template>
  <div style="padding-right: 20px">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-form">
      <el-form-item class="arae-item" label="地区" prop="provinceAllCode">
        <el-cascader
          v-model="form.provinceAllCode"
          :options="areaOptions"
          placeholder="请选择地区"
          @change="hanldArea"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="addressDetail">
        <el-input v-model="form.addressDetail" placeholder="请填写详细地址"></el-input>
      </el-form-item>
      <el-form-item prop="checked">
        <el-checkbox border v-model="form.checked">是否为默认地址</el-checkbox>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="onClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "PublicAdd",
  data() {
    return {
      debug: 1,
      isRepeat: false,
      areaOptions: regionData,

      form: {
        provinceAllCode: null,
        provinceAllname: null,
        addressDetail: null,
        checked: null,
      },
      rules: {
        provinceAllCode: {
          required: true,
          message: "请选择地区",
          trigger: "change",
        },
        addressDetail: {
          required: true,
          message: "请填写详细地址",
          change: "blur",
        },
      },
    };
  },

  methods: {
    hanldArea(val) {
      this.form.provinceAllname = val.map((item) => {
        return CodeToText[item];
      });
    },

    submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.checked = this.form.checked ? "1" : "0";
      formData.attribute = 0
      let params = {
        steelAddressInfoVOIOV: formData,
      };

      this.isRepeat = true;
      this.$api
        .consignOrder_insertSteelAddressInfo(params)
        .then((res) => {
          this.isRepeat = false;
          if (res) {
            this.$notify({
              title: "成功",
              message: "新增地址成功",
              type: "success",
            });
            this.onClose(true);
          }
        })
        .catch((err) => {
          this.isRepeat = false;
        });
    },

    onSubmit() {
      if (this.isRepeat) return;
      this.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          this.log("error submit!!");
          return false;
        }
      });
    },
    onClose(val) {
      this.$emit("visible", val);
    },
  },
};
</script>

<style lang="sass" scoped>
.footer 
  margin-top: 50px;
  display: flex;
  justify-content: center;

/deep/ .el-select,
/deep/ .el-cascader--mini 
  width: 100%;

</style>